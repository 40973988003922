import React from "react";
import Moment from "react-moment";
// react-bootstrap components
import {
  Card,
  Table,
  Button,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Input, InputGroup, Whisper, Modal, Drawer } from "rsuite";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth from "../../../restApiService/auth";
import appSettingsServices from "../../../restApiService/appSettings";
import deliver from "../../../restApiService/auth";
import wallet from "../../../restApiService/wallet";
import transaction from "../../../restApiService/transaction";
import Empty from "../../../components/Empty/Empty";

import avatar from "../../../assets/images/avatars/default-profile-picture-transparent-image.png";

class TableList extends React.Component {
  state = {
    item: [],
    dataList: [],
    entityDataTmp: [],
    selectedUser: [],
    selectedId: 0,
    livo_wallets: [],
    appData: [],
    createModalIsOpen: false,
    editModalIsOpen: false,
    majModalIsOpen: false,
    ratingModalIsOpen: false,
    deleteModalIsOpen: false,
    dataSending: false,
    optionContent: 1,
    rate_01: 0,
    rate_02: 0,
    rate_03: 0,
    rate_04: 0,
    rate_05: 0,
  };
  handleChange = (event) => {
    let appData = { ...this.state.appData };
    const { name, value } = event.target;
    let item = { ...this.state.item };

    if (name === "price") {
      item[name] = value;
      item["delivery_number"] = Number(value) / appData.delivery_cost;
    } else {
      item[name] = value;
      item["price"] = Number(value) * appData.delivery_cost;
    }

    this.setState({ item });
  };

  handleSearch = (name, event) => {
    let type = name;
    console.log("event.target.value.length", event.target.value.length);

    if (name == "full-name") {
      if (event.target.value.length >= 2) {
        const conformData = this.state.entityDataTmp.filter(
          (data) => data?.first_name && data?.last_name
        );
        this.setState({
          dataList: conformData.filter(
            (item) =>
              item?.first_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase()) ||
              item?.last_name
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
          ),
        });
      }
      if (event.target.value.length === 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    } else if (name == "createdAt") {
      if (event.target.value.length >= 10) {
        this.setState({
          dataList: this.state.entityDataTmp.filter((item) =>
            item.createdAt
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          ),
        });
      }
      if (event.target.value.length === 0) {
        this.setState({
          dataList: this.state.entityDataTmp,
        });
      }
    }
  };

  getAppSettings = () => {
    auth.initToken().then((res) => {
      appSettingsServices
        .settings(res.data.token)
        .then((res) => {
          this.setState({ appData: res.data[0] });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleNumber = (name, event) => {
    let type = name;
    if (event.target.value.length >= 2) {
      const conformData = this.state.entityDataTmp.filter(
        (data) => data?.contact
      );
      this.setState({
        dataList: conformData.filter((item) =>
          item?.contact.toLowerCase().includes(event.target.value.toLowerCase())
        ),
      });
    }
    if (event.target.value.length === 0) {
      this.setState({
        dataList: this.state.entityDataTmp,
      });
    }
  };

  editModal = (item) => {
    this.getUserModal(item.id);
    this.rateModal(item);
    this.setState({
      item: item,
      editModalIsOpen: true,
      optionContent: 1,
    });
  };

  deleteModal = (item) => {
    this.setState({
      item: item,
      deleteModalIsOpen: true,
    });
  };

  rateModal = (item) => {
    var elementCounts = item.deliver.reduce(
      (count, item) => ((count[item.rate] = count[item.rate] + 1 || 1), count),
      []
    );
    console.log(elementCounts[5]);
    this.setState({
      item: item,
      // ratingModalIsOpen: true,
      rate_01: elementCounts[1] ?? 0,
      rate_02: elementCounts[2] ?? 0,
      rate_03: elementCounts[3] ?? 0,
      rate_04: elementCounts[4] ?? 0,
      rate_05: elementCounts[5] ?? 0,
    });
  };

  entityList() {
    const { token } = this.props.currrentUser;
    deliver
      .userInfoList(token)
      .then((req) =>
        this.setState({ dataList: req.data, entityDataTmp: req.data })
      );
  }

  onChangeHandler = () => {
    const { token } = this.props.currrentUser;
    this.setState({
      dataSending: true,
      Error: false,
    });

    let item = { ...this.state.item };

    if (Object.keys(this.state.item).length > 0) {
      item["profilTypeId"] = 2;
      deliver
        .signUp(item, token)
        .then((res) => {
          // deliver wallet create creation
          let walletItem = {
            solde: 0,
            delivery: 0,
            livoUserId: res.data.user.id,
          };
          wallet
            .createWallet(walletItem, token)
            .then((res) => {})
            .catch((err) => {});
          this.componentDidMount();
        })
        .catch((error) => {
          this.setState({
            dataSending: false,
          });
        });
    } else {
      this.setState({
        dataSending: false,
      });
      this.setState({
        NotifyTrigger: true,
        icon: "alert-triangle",
        message: "Informations de connexion incorrect",
      });
      setTimeout(() => {
        this.setState({
          NotifyTrigger: false,
        });
      }, 4000);
    }
  };

  updateUserInfo = () => {
    const { token } = this.props.currrentUser;
    deliver
      .updateUserAccount(this.state.item.id, this.state.item, token)
      .then((res) => {
        this.componentDidMount();
        // setTimeout(() => {
        //   const currentItem = this.state.dataList.filter(
        //     (item) => item.id === this.state.item.id
        //   );
        //   this.setState({ editModalIsOpen: false, item: currentItem });
        // }, 400);
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  updateUserStatus = (itemData, status) => {
    // const promiseVar = toast.loading('Traitement en cours...')
    const { token } = this.props.currrentUser;
    let data = {
      email: itemData.email,
      status: status,
    };
    deliver
      .updateUserAccount(itemData.id, data, token)
      .then((res) => {
        this.componentDidMount();
        let item = { ...this.state.item };
        item["status"] = status;
        this.setState({ item });
        toast.success("Opération éffectuée avec succès", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  deleteUser = () => {
    const { token } = this.props.currrentUser;
    deliver
      .deleteUserAccount(this.state.item.id, token)
      .then((res) => {
        this.componentDidMount();
        this.setState({ deleteModalIsOpen: false });
        toast.success("Opération éffectuée avec succès", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  updateUserWallet = (event) => {
    const { token } = this.props.currrentUser;
    event.preventDefault();
    wallet
      .getWalletByDeliverId(this.state.selectedId, token)
      .then((res) => {
        console.info("getWalletByDeliverId", res.data);
        let item = { ...this.state.item };
        let walletItem = {
          solde: Number(res.data[0].solde) + Number(item.price),
          delivery: Number(res.data[0].delivery) + Number(item.delivery_number),
        };
        let transactionItem = {
          amount: Number(item.price),
          transaction_type: 1,
          livoWalletId: res.data[0].id,
          livoUserId: res.data[0].livoUserId,
        };
        this.setState({ majModalIsOpen: false });
        this.entityList();
        wallet.updateWallet(res.data[0].id, walletItem);
        transaction.transactionCreate(transactionItem);
        this.componentDidMount();
        this.setState({
          editModalIsOpen: false,
        });
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  updateUserWalletBalance = (event) => {
    const { token } = this.props.currrentUser;
    event.preventDefault();
    wallet
      .getWalletByDeliverId(this.state.selectedId, token)
      .then((res) => {
        console.info("getWalletByDeliverId", res.data);
        let item = { ...this.state.item };
        let walletItem = {
          solde: Number(item.price),
          delivery: Number(item.delivery_number),
        };
        this.setState({ majModalIsOpen: false });
        this.entityList();
        wallet.updateWallet(res.data[0].id, walletItem);
        this.componentDidMount();
        this.setState({
          editModalIsOpen: false,
        });
      })
      .catch((err) => {
        console.error("wallet error", err);
      });
  };

  Millier = (nombre) => {
    let nb = new Intl.NumberFormat().format(nombre);
    return nb;
  };

  optionSwither = (step) => {
    this.setState({ optionContent: step });
  };

  componentDidMount() {
    this.entityList();
    this.getAppSettings();
  }

  getUserModal = (id) => {
    const { token } = this.props.currrentUser;
    deliver.userInfoById(id, token).then((req) =>
      this.setState({
        selectedId: id,
        selectedUser: req.data[0],
        livo_wallets: req.data[0]?.livo_wallets[0],
        // majModalIsOpen: true,
      })
    );
  };

  render() {
    const { optionContent, rate_01, rate_02, rate_03, rate_04, rate_05 } =
      this.state;
    const hideModal = () => {
      this.setState({
        editModalIsOpen: false,
        deleteModalIsOpen: false,
      });
    };
    const PersonnalInfo = (
      <div className="row p-4 text-justify">
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label for="civility">
              Civilité <span className="text-red">*</span>
            </label>
            <select
              className="form-control"
              name="civility"
              id="civility"
              value={this.state.item.civility}
              onChange={this.handleChange}
            >
              <option>Sélectionnez...</option>
              <option value="M">M.</option>
              <option value="Mme">Mme</option>
              <option value="Mlle">Mlle</option>
            </select>
          </div>
        </div>

        <div className="col-md-9">
          <div className="form-group mb-3">
            <label for="last_name">
              Nom <span className="text-red">*</span>
            </label>
            <input
              type="text"
              className="form-control ToUpperCase"
              placeholder="Entrez votre nom"
              name="last_name"
              id="last_name"
              value={this.state.item.last_name}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group mb-3">
            <label for="last_name">
              Prénom <span className="text-red">*</span>
            </label>
            <input
              type="text"
              className="form-control ToCapitalizeCase"
              placeholder="Entrez votre prénom"
              name="first_name"
              id="first_name"
              value={this.state.item.first_name}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group mb-3">
            <label for="email">
              E-mail
              <span className="text-red">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="Entrez votre e-mail"
              name="email"
              id="email"
              value={this.state.item.email}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group mb-3">
            <label for="contact">
              Contact
              <span className="text-red">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Entrez le contact"
              name="contact"
              id="contact"
              value={this.state.item.contact}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="col-md-12  p-4 text-right w-100">
          {this.state.dataSending003 ? (
            <button className="st-btn-md st-bg-black text-white w-100 p-h-20">
              Modification...
            </button>
          ) : (
            <button
              className="st-btn-md st-bg-yellow text-black f-w-600 w-100 p-h-20"
              onClick={this.updateUserInfo}
            >
              Modifier
            </button>
          )}
        </div>
      </div>
    );
    const SoldeBox = (
      <>
        <div className="solde-box row">
          <div className="col-md-12">
            <div className="text-left ps-3 pe-3">
              <p>
                <strong>Solide actuel: </strong>
                {this.state.livo_wallets?.solde}
              </p>
              <p>
                <strong>livraisons restantes: </strong>
                {this.state.livo_wallets?.delivery}
              </p>
            </div>
          </div>
        </div>
        <hr></hr>

        <div className="solde-box row ps-3 pe-3">
          <div className="col-md-12">
            <h3>Créditer le solde</h3>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label for="price">Montant de recharge</label>
              <input
                type="text"
                className="form-control ToUpperCase"
                placeholder="0"
                name="price"
                id="price"
                value={this.state.item.price}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label for="delivery_number">Nombre de livraison</label>
              <input
                type="text"
                className="form-control ToCapitalizeCase"
                placeholder="0"
                name="delivery_number"
                id="delivery_number"
                value={this.state.item.delivery_number}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="col-md-12 text-right w-100">
            {this.state.dataSending003 ? (
              <button
                className="st-btn-md st-bg-yellow text-black f-w-600 p-h-20"
                disabled
              >
                Rechargement...
              </button>
            ) : (
              <button
                className="st-btn-md st-bg-yellow text-black f-w-600 w-100 p-h-20"
                onClick={this.updateUserWallet}
              >
                Recharger le compte
              </button>
            )}
          </div>
        </div>
        <hr></hr>
        <div className="row ps-3 pe-3">
          <div className="col-md-12">
            <h3>Balance du solde</h3>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label for="price">Montant du solde</label>
              <input
                type="text"
                className="form-control ToUpperCase"
                placeholder="0"
                name="price"
                id="price"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-3">
              <label for="delivery_number">Nombre de livraison</label>
              <input
                type="text"
                className="form-control ToCapitalizeCase"
                placeholder="0"
                name="delivery_number"
                id="delivery_number"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
          <div className="col-md-12 text-right w-100">
            {this.state.dataSending003 ? (
              <button
                className="st-btn-md st-bg-yellow text-black f-w-600 p-h-20"
                disabled
              >
                Mettre à jour...
              </button>
            ) : (
              <button
                className="st-btn-md st-bg-yellow text-black f-w-600 w-100 p-h-20"
                onClick={this.updateUserWalletBalance}
              >
                Enregistrer
              </button>
            )}
          </div>
        </div>
      </>
    );
    const Evaluation = (
      <div className="rate-box text-center">
        <h3 className="mb-4">
          Evaluation de{" "}
          {this.state.item.first_name + " " + this.state.item.last_name}{" "}
        </h3>
        <ul>
          <li>
            <div className="rate-item">
              <span>
                1 <i className="fas fa-star"></i>
              </span>
              {rate_01}
            </div>
          </li>
          <li>
            <div className="rate-item">
              <span>
                2 <i className="fas fa-star"></i>
              </span>
              {rate_02}
            </div>
          </li>
          <li>
            <div className="rate-item">
              <span>
                3 <i className="fas fa-star"></i>
              </span>
              {rate_03}
            </div>
          </li>
          <li>
            <div className="rate-item">
              <span>
                4 <i className="fas fa-star"></i>
              </span>
              {rate_04}
            </div>
          </li>
          <li>
            <div className="rate-item">
              <span>
                5 <i className="fas fa-star"></i>
              </span>
              {rate_05}
            </div>
          </li>
        </ul>
      </div>
    );
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">Listes des livreurs</Card.Title>
                  {/* <p className="card-category">
                    Here is a subtitle for this table
                  </p> */}
                </Card.Header>
                <Card.Body className="table-full-width table-responsive p-0">
                  <div className="setting-box d-flex p-4">
                    <div className="form-item mr-4">
                      <label>Nom / prénom</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(event) =>
                          this.handleSearch("full-name", event)
                        }
                      />
                    </div>
                    <div className="form-item mr-4">
                      <label>Numero de téléphone</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(event) =>
                          this.handleNumber("contact", event)
                        }
                      />
                    </div>
                    <div className="form-item mr-4">
                      <label>Status</label>
                      <select
                        className="form-control"
                        onChange={(event) => this.handleSearch("status", event)}
                      >
                        <option value={0}></option>
                        <option value={1}>Actif</option>
                        <option value={2}>Suspendu</option>
                      </select>
                    </div>
                    <div className="form-item">
                      <label>Date d'inscription</label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(event) =>
                          this.handleSearch("createdAt", event)
                        }
                      />
                    </div>
                  </div>
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">Nom et Prenom</th>
                        <th className="border-0">Contact</th>
                        {/* <th className="border-0">Statut</th> */}
                        <th className="border-0">Solde</th>
                        <th className="border-0 text-center-important">
                          livraisons
                        </th>
                        <th className="border-0 text-center-important">
                          Commandes
                        </th>
                        <th>Inscription</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.dataList.map(
                        (data, index) =>
                          data.livoProfilTypeId == 2 && (
                            <tr key={index}>
                              <td>
                                <div className="user-identity">
                                  <img
                                    src={avatar}
                                    alt=""
                                    className="s25x me-2"
                                  />
                                  {data.last_name} {data.first_name}
                                </div>
                              </td>
                              <td>{data.contact}</td>
                              {/* <td>
                                {[2, null].includes(data.status)
                                  ? "Inactif"
                                  : "Actif"}
                              </td> */}
                              <td>
                                {this.Millier(data.livo_wallets[0]?.solde)} FCFA
                              </td>
                              <td className="text-center">
                                {this.Millier(data.livo_wallets[0]?.delivery)}
                              </td>
                              <td className="text-center">
                                {data.deliver.length}
                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {data.createdAt}
                                </Moment>
                              </td>
                              <td className="td-actions text-right">
                                <button
                                  className="action-btn rounded-full"
                                  onClick={() => this.editModal(data)}
                                >
                                  <i className="fas fa-cog"></i>
                                </button>
                                <button
                                  className="action-btn rounded-full"
                                  onClick={() => this.deleteModal(data)}
                                >
                                  <i className="fas fa-trash"></i>
                                </button>

                                {data.status === 1 ? (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-488980961">
                                        Désactiver
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      className="bg-danger action-btn text-white rounded-full"
                                      onClick={() =>
                                        this.updateUserStatus(data, 2)
                                      }
                                    >
                                      <i className="fas fa-power-off"></i>
                                    </button>
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-488980961">
                                        Activer
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      className="action-btn rounded-full"
                                      onClick={() =>
                                        this.updateUserStatus(data, 1)
                                      }
                                    >
                                      <i className="fas fa-power-off"></i>
                                    </button>
                                  </OverlayTrigger>
                                )}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </Table>
                  {this.state.dataList.length < 1 && <Empty />}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal open={this.state.editModalIsOpen} onClose={hideModal}>
          <Modal.Header>
            <Modal.Title>
              LIVO ADMIN | Compte :{" "}
              {this.state.selectedUser.first_name +
                " " +
                this.state.selectedUser.last_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="term">
            <div className="row p-2 text-center">
              <div className="col-md-4 text-center">
                <button
                  className={
                    optionContent === 1
                      ? "option-action-btn active"
                      : "option-action-btn"
                  }
                  onClick={() => this.optionSwither(1)}
                >
                  <i className="fas fa-edit"></i>
                  Info. personnel
                </button>
              </div>
              <div className="col-md-4 text-center">
                <button
                  className={
                    optionContent === 2
                      ? "option-action-btn active"
                      : "option-action-btn"
                  }
                  onClick={() => this.optionSwither(2)}
                >
                  <i class="fas fa-money-check"></i>
                  Solde
                </button>
              </div>
              <div className="col-md-4 text-center">
                <button
                  className={
                    optionContent === 3
                      ? "option-action-btn active"
                      : "option-action-btn"
                  }
                  onClick={() => this.optionSwither(3)}
                >
                  <i className="fas fa-star"></i>
                  Evaluation
                </button>
              </div>
            </div>
            <hr></hr>
            {optionContent === 1 && PersonnalInfo}
            {optionContent === 2 && SoldeBox}
            {optionContent === 3 && Evaluation}
          </Modal.Body>
        </Modal>
        <Modal open={this.state.deleteModalIsOpen} onClose={hideModal}>
          <Modal.Header>
            <Modal.Title>LIVO ADMIN | Suppression de compte</Modal.Title>
          </Modal.Header>
          <Modal.Body className="term">
            <div className="text-center">
              <p className="m-4">
                Voulez-vous supprimer le compte de{" "}
                <strong>
                  {this.state.item.first_name +
                    " " +
                    this.state.item.last_name ?? ""}
                </strong>{" "}
                ?
              </p>
              <button
                onClick={hideModal}
                className="st-btn-md st-bg-black text-white f-w-600 p-h-20 me-4"
              >
                Non
              </button>
              <button
                onClick={this.deleteUser}
                className="st-btn-md st-bg-yellow text-white f-w-600 p-h-20"
              >
                Oui
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    currrentUser: state.userInfoReducer.addedUserInfos,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
