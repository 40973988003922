import axios from "axios";
import config from "./config";

const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  initToken: () => {
    return axios.get(`${config.URL_END_POINT}/auth/generate/token`);
  },
  signUp: (data, token) => {
    // console.log(data);
    return axios.post(
      `${config.URL_END_POINT}/auth/signup`,
      data,
      headersConfig(token)
    );
  },
  signIn: (data) => {
    // console.log(data);
    return axios.post(`${config.URL_END_POINT}/admin/auth/signin`, data);
  },
  requestResetPassword: (id, data, token) => {
    return axios.post(
      `${config.URL_END_POINT}/auth/requestResetPassword`,
      data,
      headersConfig(token)
    );
  },
  resetPassword: (id, token) => {
    return axios.post(
      `${config.URL_END_POINT}/auth/resetPassword`,
      headersConfig(token)
    );
  },
  userInfoList: (token) => {
    return axios.get(
      `${config.URL_END_POINT}/account/list`,
      headersConfig(token)
    );
  },
  userListByDate: (id, date, token) => {
    return axios.get(
      `${config.URL_END_POINT}/account/user/${id}/${date}`,
      headersConfig(token)
    );
  },
  userListByDateRange: (id, startDate, endDate, token) => {
    return axios.get(
      `${config.URL_END_POINT}/account/user/${id}/${startDate}/${endDate}`,
      headersConfig(token)
    );
  },
  userInfoById: (id, token) => {
    return axios.get(
      `${config.URL_END_POINT}/account/${id}`,
      headersConfig(token)
    );
  },
  updateUserPassword: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/account/${id}/pwd/update`,
      data,
      headersConfig(token)
    );
  },
  updateUserAccount: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/account/update/${id}`,
      data,
      headersConfig(token)
    );
  },
  updateUserAccountInfo: (id, data, token) => {
    return axios.put(
      `${config.URL_END_POINT}/account/simple/update/${id}`,
      data,
      headersConfig(token)
    );
  },
  deleteUserAccount: (id, token) => {
    return axios.delete(
      `${config.URL_END_POINT}/account/delete/${id}`,
      headersConfig(token)
    );
  },
};
