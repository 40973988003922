import axios from "axios";
import config from "./config";

const headersConfig = (token) => {
  const head = { headers: { Authorization: `Bearer ${token}` } };
  return head;
};

export default {
  transactionCreate: (data) => {
    return axios.post(`${config.URL_END_POINT}/transaction/add`, data);
  },
  transactionList: () => {
    return axios.get(`${config.URL_END_POINT}/transaction/list`);
  },
  transactionById: (id) => {
    return axios.get(`${config.URL_END_POINT}/transaction/${id}`);
  },
};
